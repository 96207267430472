<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <orisis-logo-full />
        <!-- <h2 class="brand-text text-primary ml-1">ORISIS</h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Bienvenue sur ORISIS 👋 
          </b-card-title>
          <b-card-text class="mb-2">
            Saisissez vos identifiants pour accéder à votre logiciel
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="Email" label-for="login_email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="email"
                    :state="errors.length > 0 ? false : null"
                    name="login_email"
                    placeholder="orisis@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login_password">Mot de passe</label>
                  <b-link :to="{ name: 'auth-forgot-password-v2' }">
                    <small>Mot de passe oublié ?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login_password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="rememberMe"
                  name="checkbox-1"
                >
                  Se souvenir de moi
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Se connecter
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Pas encore client ? </span>
            <b-link :to="{ name: 'register' }">
              <span>&nbsp;Inscrivez-vous !</span>
            </b-link>
          </b-card-text>

          <!-- divider -->


          <div class="divider my-2">
            <div class="divider-text">
              ou
            </div>
          </div>

          <div class="auth-footer-btn d-flex justify-content-center">
            <!-- <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button> -->
                      <!-- <div class="g-signin2" data-onsuccess="onSignIn"></div> -->

                <b-button
              variant="google"
            @click="loginWithGoogle()"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <!-- <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button> -->
          </div>
          
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, defineRule, configure, localize } from 'vee-validate';

  import { required, url, email } from "@validations";

configure({
  // Generates an English message locale generator
  generateMessage: localize('fr', {
    messages: {
        url: 'Ce champ doit être une URL',  
        required: 'Ce champ est requis',
        email: 'Ce champ doit être un email',
      
    },
  }),
});
localize('fr')

import OrisisLogoFull from "@core/layouts/components/LogoFull.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";
export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    OrisisLogoFull,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-bg2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email: "",
      password: "",
      rememberMe:false
    };
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
//     loginWithGoogle () {
//   this.$gAuth
//   .signIn()
//   .then(GoogleUser => {
//     // on success do something
//     console.log('GoogleUser', GoogleUser)
//     console.log('getId', GoogleUser.getId())
//     console.log('getBasicProfile', GoogleUser.getBasicProfile())
//     console.log('getAuthResponse', GoogleUser.getAuthResponse())
//     let info ={
//       basicProfile: GoogleUser.getBasicProfile(),
//       authResponse: GoogleUser.getAuthResponse()
//     }
//     this.$store.dispatch("oAuthToken", {that: this, oAuthInfo :info})
//   })
//   .catch(error => {
//     console.log('error', error)
//   })
// },
    validationForm() {
      var payload = {
        email: this.email,
        password: this.password,
        rememberMe: this.rememberMe
      };
    var that = this;

      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.$store.dispatch('login', {payload:payload, that: that})
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
